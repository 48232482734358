<template>
  <div class="pageview">
    <van-nav-bar title="网上办事" fixed left-arrow @click-left="onCanback" />
    <div class="applybzview">
      <van-cell-group>
        <van-cell :label="workInfo.AcceptScope">
          <template #title>
            <van-tag type="danger">第1步</van-tag>
            <span class="custom-title">请仔细阅读受理范围</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="applybzview">
      <van-cell-group>
        <van-cell :label="workInfo.ApplyMaterials">
          <template #title>
            <van-tag type="danger">第2步</van-tag>
            <span class="custom-title">请准备申请材料</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="applybzview">
      <van-cell-group>
        <van-cell :label="workInfo.Procedure">
          <template #title>
            <van-tag type="danger">第3步</van-tag>
            <span class="custom-title">请知晓办理流程</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="applybzview">
      <van-cell-group>
        <van-cell :border="false">
          <template #title>
            <van-tag type="danger">第4步</van-tag>
            <span class="custom-title">请下载相关材料</span>
          </template>
        </van-cell>
        <div class="downloadinfo">
          <a v-for="(item, index) in workInfo.Tables" :key="index">
            <span class="f28 t-center">{{ item.ChnName }}</span>
            <van-icon name="arrow" />
          </a>
        </div>
      </van-cell-group>
    </div>
    <div class="applybzview">
      <van-cell-group>
        <div style="width: 50%; margin: 0 auto;padding:30px 0;">
          <img src="../../../assets/erweima.jpg" width="100%" />
          <div>扫一扫注册微信小程序</div>
        </div>
      </van-cell-group>
    </div>
    <TipsMiniProject :show.sync="showTips" />
  </div>
</template>

<script>
import { WeGetNWMatter } from "@/api/Network";
import TipsMiniProject from "@/components/TipsMiniProject";
export default {
  components: {
    TipsMiniProject,
  },
  data() {
    return {
      workInfo: [],
      showTips: false,
    };
  },
  created() {
    this.getNWMatter({ nwmId: this.$route.params.NWMId });
  },
  methods: {
    getNWMatter(data) {
      WeGetNWMatter(data).then((res) => {
        this.workInfo = res.data.data;
      });
    },
    // 后退按钮
    onCanback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.pageview {
  background: #f7f8fa;
  min-height: 100%;
  padding-top: 45px;
}
.applybzview {
  padding: 10px 10px 0px;
}
.custom-title {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
}
.van-cell__label {
  font-size: 14px;
}
.downloadinfo {
  padding: 15px;
  font-size: 14px;
  line-height: 20px;
}
.downloadinfo i {
  float: right;
}
.downloadinfo a {
  display: inline-block;
  clear: both;
  width: 100%;
}
.QrCode {
  text-align: center;
}
.QrCode img {
  width: 350px;
  height: 300px;
}
.QrCode div {
  width: 350px;
}
</style>